import "../Css/MobileMenuBurger.css";

const MobileMenuBurger = (props) => {

    return (
        <div className="mobile-menu-icon">
            <label href="javascript:" htmlFor="mobile-menu-trigger">
                <input type="checkbox" id="burger-icon-trigger" className="burger-icon-trigger" />
                <div className="burger-icon active">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </label>
        </div>
    );

};

export default MobileMenuBurger;