// import classes from './Loading.module.css';

import PageTemplate from './PageTemplate';
import OccupationsDesc from '../Components/OccupationsDesc';
import OccupationsGrid from '../Components/OccupationsGrid';
import QnaMeet from '../Components/QnaMeet';



const Occupations = (props) => {

    return (
        <PageTemplate>
            <div className="main">
                <OccupationsDesc />
                <OccupationsGrid />
            </div>
            <QnaMeet />
        </PageTemplate>
    );

};

export default Occupations;