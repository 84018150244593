const FormThankYou = (props) => {

    return (
        <center>
            <br/><br/>
            
            <p>
                <b style={{color:'#008800'}}>תודה על פניתכם!</b>
            </p>
            <p>
                פניתכם נקלטה ואנו ניצור<br />אתכם קשר בהקדם
            </p>
            
            <br/><br/>
        </center>
    );

};

export default FormThankYou;