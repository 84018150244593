import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetMenu, GetMenuItem } from '../Util/Data';

import '../Css/Menu.css';


const Menu = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [stateMenu, setStateMenu] = useState([]);
    const [stateCurrentPage, setStateCurrentPage] = useState({});

    useEffect(() => {
        setStateMenu(GetMenu());
        setStateCurrentPage(GetMenuItem(location.pathname));
    }, []);


    return (
            <ul className="menu">
                { stateMenu.map((menuItem) => { 
                    return (
                        <li key={menuItem.id} className={ (stateCurrentPage.trajectory.includes(menuItem.title)) ? 'active' : ''}>
                            <a href="javascript:" onClick={() => { navigate(menuItem.link); window.scrollTo(0,0); }}>{menuItem.title}</a>
                            { (menuItem.submenu && menuItem.submenu.filter(si => si.display).length > 0)
                                ?   <div className="sub-menu">
                                        { menuItem.submenu.filter(sm => sm.display).map((subMenuItem) => { 
                                            return (
                                                <a href="javascript:" key={ subMenuItem.id } onClick={ () => { navigate(subMenuItem.link); window.scrollTo(0,0); } }>{ subMenuItem.title }</a>
                                            )
                                        }) }
                                    </div>

                                :   <></>
                            }
                        </li>
                    )
                }) }
            </ul>
    );

};

export default Menu;