import { useLocation } from 'react-router-dom';
import PageTemplate from './PageTemplate';

import AccessibilityImage from '../img/accessibility.png';

import '../Css/Accessibility.css';


const Accessibility = (props) => {
    const location = useLocation();

    return (
        <PageTemplate>

            <br /><br /><br />

            <div className="box box-shadow box-middle box-AccessibilityPage">
                <div className="box-padding">

                    <h1>הצהרת נגישות</h1>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#C2873F', width: '140px', height: '2px', marginBottom: '22px'}}></div>


                    <p>
                        אנו משקיעים זמן ומאמץ רב בהנגשת האתר מתחילת בנייתו.
                        האתר מונגש בהתאם לתקנה 35, ת.י. 5568 
                        להנגשת אתרי אינטרנט ו- Wcag 2.0.
                        בחרתי בתקן AA.
                        בדף זה אפרט על הכלים והשיטות בהן השתמשתי להנגשת האתר,
                        וכן הסבר איך לפנות אלנו אם יש צורך בתיקון תקלה או שיפור הנגישות.
                    </p>

                    <h2>האתר הונגש באופן הבא:</h2>
                    <ol>
                        <li>התאמת האתר לכל סוגי הדפדפנים המודרניים (כלומר כולם חוץ מאינטרנט אקספלורר).</li>
                        <li>התאמת האתר לכל סוגי הפלטפורמות – מובייל, טבלטים ודסקטופים.</li>
                        <li>התאמת האתר מבחינת שימוש במקלדת בלבד (למעט ניידים וטאבלטים).</li>
                        <li>התאמת האתר לאנשים עם לקות ראיה חלקית או מלאה.</li>
                        <li>התאמת האתר והתכנים באתר לאנשים עם לקות שמיעתית חלקית או מלאה.</li>
                    </ol><br/>

                    <h2>על מנת להנגיש את האתר ותכניו, נעשו השינויים הבאים:</h2>
                    <ol>
                        <li>שינויים ובדיקות באתר על מנת שיתאים לכל הדפדפנים ולכל הפלטפורמות (ריספונסיביות).</li>
                        <li>הוספת access keys באופן שאינו יסתור את פעולות המקלדת הטבעיות ווידוא שניתן להגיע לכל חלקי האתר עם מקלדת בלבד.</li>
                        <li>בדיקת ניגודיות בצבעים.</li>
                        <li>הוספת טקסט הסבר לכל תמונה באתר.</li>
                        <li>ניתן לגדיל את הטקסט והתמונות באתר על ידי שימוש בגלגלת העכבר והמקלדת.</li>
                    </ol><br/>

                    <h2>הכלים בהם השתמשתי לבדיקת הנגישות הם:</h2>
                    <ol>
                        <li>תקן הנגישות עצמו כפי שמפורט באתר ה-W3C.</li>
                        <li>קריטריוני הבדיקה כפי שמופיעים באתר הנגישות הישראלי.</li>
                        <li>סריקה ידנית של קוד המקור של האתר.</li>
                        <li>שימוש ב-WAVE -לבדיקת הניגודיות באתר, לבדיקת מבנה האתר ולסריקה כללית לפי תקן WCAG2.</li>
                        <li>שימוש בכלי Lighthouse של הדפדפן לבדיקת נגישות.</li>
                    </ol>

                    <p>
                        דוגמה לאחת מבדיקות הנגישות<br />
                        <img src={ AccessibilityImage } style={{maxWidth:'100%'}} alt="דוגמה לאחת מבדיקות הנגישות" />
                    </p>

                    <p>
                        כפי שניתן לראות, בהנגשת האתר הושקעו זמן ומאמצים מרובים. 
                        במידה ומצאתם בעיות נגישות, אשמח מאוד אם תפנו אלי. ניתן לפנות אלי באמצעות המייל: <a style={{color:'var(--color-brown-darker)'}} href="mailto:orit@lissak-adv.com">orit@lissak-adv.com</a>.
                    </p>

                </div>
            </div>

        </PageTemplate>
    );

};

export default Accessibility;