import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetOccupationsList } from '../Util/Data';

import "../Css/OccupationsGrid.css";




const OccupationsGrid = (props) => {
    const navigate = useNavigate();
    const [stateOccupationsList, setStateOccupationsList] = useState([]);

    useEffect(() => {
        setStateOccupationsList(GetOccupationsList());
    }, []);


    return (
        <div className="occupations-grid">
            { stateOccupationsList.filter(occ => occ.display).map((occ) => {
                return (
                    <div key={occ.id} className="occupation-box" onClick={() => {navigate(occ.link); window.scrollTo(0,0);}}>
                        <div className="inner">
                            <i className={`icon-big familaw-1-${occ.icon}`}></i>
                            <h2>{occ.title}</h2>
                            <div className="divider-line"></div>
                            <p>{ occ.subtitle }</p>
                            <a className="more-info" onClick={() => {navigate(occ.link); window.scrollTo(0,0);}}>מידע נוסף</a>
                        </div>
                    </div>
                )
            }) }
        </div>
    );
};

export default OccupationsGrid;