// import classes from './Loading.module.css';

import PageTemplate from './PageTemplate';
import OccupationsDesc from '../Components/OccupationsDesc';
import OccupationsGrid from '../Components/OccupationsGrid';
import AboutHome from '../Components/AboutHome';
import ContactUs from '../Components/ContactUs';


const Home = (props) => {

    return (
        <PageTemplate>
            <div className="main">
                <OccupationsDesc />
                <OccupationsGrid />
            </div>
            <AboutHome />
            <ContactUs />
        </PageTemplate>
    );

};

export default Home;