import IconCheck from '../Svg/IconCheck';
import "../Css/AboutHome.css";


const AboutHome = (props) => {

    return (
        <div className="box box-fullwidth box-about-home">

            <div style={{display:'flex', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
                <div>
                    <h2>אודות עו"ד אורית ליסק</h2>
                    <div className="divider-line" style={{marginRight: 'auto', width: '140px', height:'2px'}}></div>
                    <div className="divider-space h2"></div>
                    <div className="divider-space h2"></div>
                    <div className="divider-space h2"></div>
                </div>
            </div>


            <div className="box box-2s box-fullwidth" style={{maxWidth: '1500px', marginRight: 'auto', marginLeft: 'auto'}}>

                <div className="box-col50 box-bg-orit-about">&nbsp;</div>

                <div className="box-col50 box-about-home-desc">

                    <p>בראש המשרד עומדת עורכת הדין אורית ליסק. אורית הינה בעלת תואר ראשון במשפטים (LL.B) מהקריה האקדמית אונו (בהצטיינות), ותואר שני מחקרי (LL.M) ממרכז צבי מיתר ללימודי משפט מתקדמים באוניברסיטת תל אביב (בהצטיינות). עבודת התזה של עורכת הדין ליסק, במסגרת התואר השני, עסקה בנושא חובת תום הלב בעת הגשת בקשה לרישום סימן מסחר, וזכתה לציון 95.</p>
                    <p>את ניסיונה בתחום הקניין הרוחני צברה עורכת הדין אורית ליסק בעת התמחותה ברשות הפטנטים, סימני מסחר ומדגמים, ולאחר מכן כעורכת דין במגזר הפרטי, כעצמאית ובהמשך כשכירה במחלקת קניין רוחני באחת מפירמות עורכי הדין הגדולות והוותיקות בישראל.</p>
                    <p>במקביל לעבודתה המשפטית, עורכת הדין אורית ליסק פעילה במחוז מרכז של לשכת עורכי הדין בישראל ובעבר שימשה כסגנית יו"ר וועדת קניין רוחני במחוז וכן כסגנית יו"ר וועדת הקשר רמלה. במסגרת זו אף ארגנה עורכת הדין ליסק ערבי עיון בתחום הקניין הרוחני.</p>

                </div>

            </div>
        </div>
    );
};

export default AboutHome;