import Qna from './Qna'
import Meet from './Meet'
import '../Css/QnaMeet.css';

const QnaMeet = (props) => {

    return (
            <div className="box box-shadow box-2s box-fullwidth box-qna-meet">
                <Qna />
                <Meet />
            </div>
    );

};

export default QnaMeet;