import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GetMenuItem } from '../Util/Data';

import "../Css/OccupationDesc.css";


const OccupationTopic = (props) => {
    const location = useLocation();
    const [stateOccupationTopic, setStateOccupationsTopic] = useState();

    useEffect(() => {
        setStateOccupationsTopic(GetMenuItem(location.pathname));
    }, [location.pathname]);



    return (
        <div className="box box-shadow box-2s box-middle">
            <div className="box-col50">
                <div className="box-padding occupations-topic">

                    <i className={`icon-big familaw-1-${stateOccupationTopic?.icon}`}></i>
                    <div className="divider-space h2"></div>
                    <h2>{ stateOccupationTopic?.title }</h2>
                    <div className="divider-line"></div>
                    <div className="divider-space h2"></div>

                    { stateOccupationTopic?.content }
                </div>
            </div>

            <div className="box-col50 box-bg box-bg-001">&nbsp;</div>
        </div>

    );
};

export default OccupationTopic;