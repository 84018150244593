// import classes from './Loading.module.css';

import PageTemplate from './PageTemplate';
import OccupationTopic from '../Components/OccupationTopic';
import OccupationsExt from '../Components/OccupationsExt';
import QnaMeet from '../Components/QnaMeet';



const Occupations = (props) => {
    
    return (
        <PageTemplate>
            <div className="main">
                <OccupationTopic />
                {/* <OccupationsExt /> */}
            </div>
            <QnaMeet />
        </PageTemplate>
    );

};

export default Occupations;