
const MenuTree = [
    {
        "id": "HOME",
        "title": "דף הבית",
        "link": "/",
        "path": "/",
        "trajectory": ["דף הבית"]
    },
    {
        "id": "OCCUPATIONS",
        "title": "תחומי עיסוק",
        "link": "/תחומי עיסוק",
        "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7",
        "trajectory": ["תחומי עיסוק"],
        "submenu": [
            { 
                "display": true,
                "id": "OCCUPATIONS-TRADEMARKS",
                "title": "סימני מסחר", 
                "subtitle": "רישום סימן המסחר מעניק לבעליו זכות קניינית המאפשרת לו לבדל את הסחורות או השירותים אותם הוא מספק לעומת ספקים / יצרנים אחרים, ולגרום לציבור הצרכנים לזהות את המותג כשייך לו בלבד.", 
                "link": "/תחומי עיסוק/סימני מסחר",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%A1%D7%99%D7%9E%D7%A0%D7%99%20%D7%9E%D7%A1%D7%97%D7%A8",
                "trajectory": ["תחומי עיסוק", "סימני מסחר"],
                "content": <>
                    <p>הדינים החלים על סימני המסחר בישראל מקורם בפקודת סימני מסחר [נוסח חדש], תשל"ב-1972.</p>

                    <p>סימן מסחר מוגדר בפקודה כסימן המשמש, או מיועד לשמש, לאדם לעניין הטובין שהוא מייצר או סוחר 
                    בהם. עוד על פי הפקודה, סימן מסחר יכול שיהיה מורכב מאותיות, ספרות, מלים, דמויות או אותות אחרים
                    או צירופם של אלה, <a href="/תחומי עיסוק/בקשה לרישום סימן מסחר תלת-ממדי">בשני ממדים או בשלושה</a>.
                    </p>

                    <p>במסגרת הפקודה וכן בפסיקה עניפה נקבעו כללים ומבחני עזר אשר בהתקיימם ייחשב סימן מסחר כשיר
                    לרישום בפנקס סימני המסחר.</p>

                    <p>כך למשל, על סימן המסחר להיות
                    בעל <a href="/תחומי עיסוק/אופי מבחין">אופי מבחין מולד (אינהרנטי) או בעל אופי מבחין נרכש</a>.
                    בנוסף מפרטת הפקודה שורה של סימנים אשר אינם כשירים לרישום, כגון: סימן המרמז על קשר עם נשיא המדינה,
                    דגלי המדינה או סמליה, סימן שיש בו כדי להטעות את הציבור, סימן הזהה לסימן מסחר רשום אחר או
                    שהדמיון בין שני הסימנים עשוי להטעות, ועוד.</p>

                    <p>רישום סימן המסחר מעניק לבעליו זכות קניינית המאפשרת לו לבדל את הסחורות או השירותים אותם
                    הוא מספק לעומת ספקים / יצרנים אחרים, ולגרום לציבור הצרכנים לזהות את המותג כשייך לו בלבד.</p>

                    <p>
                        ההגנה המוענקת לסימן מסחר רשום תקפה לעשר שנים, אשר בסיומן
                        ניתן <a href="/תחומי עיסוק/חידוש סימן מסחר רשום">לחדש את הסימן בכל פעם לעשר שנים נוספות</a>,
                        בכפוף לתשלום האגרה המתאימה.
                    </p>

                    <p>ניתן להגיש באמצעות משרדנו בקשות לרישום סימני מסחר וכן לקבל ליווי שוטף בהליך הרישום המתנהל
                    מול מחלקת סימני המסחר ובית הדין
                    של <a href="https://www.gov.il/he/departments/ilpo/" target="_blank">רשות הפטנטים, סימני מסחר ועיצובים</a>.
                    כמו כן, אנו מטפלים בהגשת <a href="/תחומי עיסוק/בקשה לבחינה על אתר">בקשות לזירוז הבחינה</a> לאחר הגשת הבקשה לרישום.
                    </p>

                    <p>בנוסף, משרדנו מטפל בהליכים נוספים בתחום סימני המסחר, כגון:</p>

                    <ul>
                        <li><span>טיפול בהליכים חד צדדיים במקרה של סירוב הלשכה לרישום סימן מסחר לאחר הגשת בקשה לרישום</span></li>
                        <li><span>הליכי התנגדות לרישום סימן מסחר <a href="/תחומי עיסוק/בקשות מתחרות לרישום סימני מסחר">ותחרות בין סימני מסחר</a></span></li>
                        <li><span><a href="/שאלות נפוצות/האם אפשר לבטל את סימן המסחר לאחר הרישום">בקשות למחיקה או ביטול סימן מסחר רשום</a></span></li>
                        <li><span><a href="/תחומי עיסוק/חידוש סימן מסחר רשום">חידוש סימן מסחר רשום</a></span></li>
                        <li><span>תביעות בגין הפרת סימן מסחר</span></li>
                    </ul>
                </>
            },
            { 
                "display": false,
                "id": "OCCUPATIONS-3D",
                "title": "בקשה לרישום סימן מסחר תלת-ממדי", 
                "subtitle": "", 
                "link": "/תחומי עיסוק/בקשה לרישום סימן מסחר תלת-ממדי",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%91%D7%A7%D7%A9%D7%94%20%D7%9C%D7%A8%D7%99%D7%A9%D7%95%D7%9D%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8%20%D7%AA%D7%9C%D7%AA-%D7%9E%D7%9E%D7%93%D7%99",
                "trajectory": ["תחומי עיסוק", "בקשה לרישום סימן מסחר תלת-ממדי"],
                "content": <>
                    <p>על פי ההגדרה המצויה בפקודת סימני מסחר – 1940, סימן מסחר יכול שיהיה בצורת אותיות, ספרות,
                    מילים, דמויות או אותות אחרים, בשני ממדים ואף בשלושה.</p>

                    <p>על דרך הכלל, ניתן למנות שלושה סוגי סימני מסחר תלת ממדיים: הראשון מתייחס לצורת האריזה של מוצר;
                    השני מתייחס לסימנים תלת-ממדיים שאינם קשורים למוצר או לאריזתו ואילו השלישי מתייחס לצורת המוצר
                    עצמו.</p>

                    <p>כידוע, דיני הפטנטים והעיצובים, יחד עם דיני סימני המסחר, הינם ענפים שונים של הקניין הרוחני,
                    כאשר בבסיסו של כל אחד מאותם ענפים מצויות מטרות שונות. כך למשל, ההגנה המוענקת במסגרת דיני
                    הפטנטים והעיצובים הינה תחומה בזמן, כך שכאשר מדובר במוצר בעל אופי פונקציונאלי, יוכל הממציא להגן
                    עליו באמצעות פטנט למשך 20 שנה, וכאשר מדבר במוצר בעל אופי אסתטי יוכל בעליו להגן עליו למשך כ- 25
                    שנה באמצעות רישום עיצוב. כמו כן מטרתם של דיני הפטנטים והעיצובים הינה לעודד פיתוח ויצירת המצאות
                    חדשות, אשר בתום תקופת ההגנה יהפכו לנחלת הכלל.</p>

                    <p>לעומת זאת, סימני מסחר אינם מוגבלים בזמן, ומטרתם היא לאפשר לציבור הצרכנים לזהות את מקורם של
                    טובין / מוצרים, כך שכאשר יראו את הסימן המסחרי, יקשרו אותו עם מקור מסוים. כמו כן נועדו סימני
                    המסחר לאפשר לבעליהם לבדל את סחורותיהם מאלה של יצרנים אחרים.</p>

                    <p>לפיכך, כאשר מדובר בבקשה לרישום סימן מסחר בייחס לצורתו התלת ממדית של המוצר עצמו, קיים החשש כי
                    רישומו של סימן הינו בעצם יצירת "מסלול עוקף" לדיני הפטנטים והעיצובים. לדוגמא, כאשר יפוג תוקפו
                    של פטנט מסוים, יוכל בעליו לרשום את המוצר כסימן מסחר ובכך לזכות בהגנה ללא הגבלת זמן.</p>

                    <p>פסק הדין אשר על פיו נקבעו הכללים ביחס לרישומו של המוצר עצמו כסימן מסחר הינו ע"א 11487/03
                    August Strok KG נ' אלפא אינטואיט מוצרי מזון בע"מ, הידוע בכינויו "עניין טופיפי" (נכון, על שם
                    הממתק). בעקבות עניין טופיפי הוציא רשם סימני המסחר מספר חוזרים אשר מסדירים נושא זה, כאשר האחרון
                    שבהם הינו "חוזר 033/2016 - דגשים בבחינת בקשות לרישום סימני מסחר", מיום 15.12.2016.</p>

                    <p>החוזר מדגיש כי ניתן יהיה לשקול רישום דמויות תלת ממדיות כסימני מסחר, אך רק במקרים בהם יוכח
                    באמצעות חומר ראיות, כי התקיימו 3 תנאי סף מצטברים כדלהלן:</p>

                    <ul>
                        <li><span>הדמות המבוקשת לרישום ממלאת בפועל תפקיד של סימן מסחר;</span></li>
                        <li><span>הדמות המבוקשת לרישום אינה בעלת תפקיד אסתטי או פונקציונאלי ממשי;</span></li>
                        <li><span>כתוצאה משימוש, רכשה הדמות אופי מבחין.</span></li>
                    </ul>

                    <p>לפיכך, במידה ומוגשת בקשה לרישום סימן מסחר תלת ממדי אשר עומדת בכל התנאים הנ"ל, יוכל הרשם לאשר
                    את הרישום המבוקש. זאת כמובן במידה והסימן המבוקש עומד בשאר תנאי הפקודה בעניין כשירות לרישום,
                    כגון העדר הטעיה.</p>

                    <p>על החלטת הרשם בעניין סירוב לרישום סימן מסחר תלת ממדי ניתן להגיש ערעור לבית המשפט המחוזי בתוך
                    30 יום ממתן ההחלטה.</p>

                    <p><b>
                        מבקשים לרשום סימן מסחר? מעוניינים לקבל מידע הנוגע לתנאי הכשרות לרישום סימן מסחר?
                        נשמח לעמוד לרשותכם בכל שאלה בנושא.
                    </b></p>

                    <a href="/תחומי עיסוק/סימני מסחר">חזרה לעמוד סימני מסחר</a>
                </>
            },
            { 
                "display": false,
                "id": "OCCUPATIONS-CHARACTER",
                "title": "מהו אופי מבחין?", 
                "subtitle": "", 
                "link": "/תחומי עיסוק/אופי מבחין",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%90%D7%95%D7%A4%D7%99%20%D7%9E%D7%91%D7%97%D7%99%D7%9F",
                "trajectory": ["תחומי עיסוק", "אופי מבחין"],
                "content": <>
                    <p>
                        אחד מתנאי הכשרות לרישום של סימן מסחר הוא היותו של הסימן בעל
                        "אופי מבחין". דרישה זו מעוגנת בסעיף 8 לפקודת סימני מסחר [נוסח חדש], 
                        תשל"ב – 1972, כאשר סעיף קטן (א) בו קובע כי: "אין סימן כשר לרישום 
                        כסימן מסחר אלא אם יש  בו כדי להבחין בין הטובין של בעל הסימן
                         לבין הטובין של אחרים".
                    </p>
                    <p>
                        האופי המבחין הנדרש על פי הפקודה צריך להיות אינהרנטי (מולד), 
                        כזה אשר טבוע בסימן המסחר ואינו תלוי בנסיבות חיצוניות. במילים אחרות,
                        הסימן המבוקש לרישום צריך להיות כזה שקהל הצרכנים או מקבלי השירותים יידעו
                        לזהות את הסימן ולהבדיל בינו ובין סימנים אחרים המוטבעים על סחורות אחרות.
                    </p>
                    <p>
                        לשם הקביעה מהו אותו אופי מבחין, מקובל לחלק את סימני המסחר לארבע
                        קטגוריות מרכזיות הממוקמות על "ציר התיאוריות", כפי שהגדיר זאת בית המשפט: סימנים 
                        דמיוניים או שרירותיים אשר זוכים למידת ההגנה הגבוה ביותר; סימנים מרמזים הזוכים
                        להגנה מוגברת; סימנים מתארים אשר יזכו להגנה מועטה ביותר ובמקרים נדירים;
                        וסימנים גנריים אשר לא יזכו להגנה כלל.
                    </p>
                    <p>
                        ככל שמיקומו של הסימן על ציר התיאוריות יהיה קרוב יותר למשפחת
                         הסימנים הדמיוניים או השרירותיים, כך יהיה קל יותר לקבוע כי הוא
                          בעל אופי מבחין.
                    </p>

                    <p>
                        לעיתים, על אף העדרו של אופי מבחין מולד 
                        (אינהרנטי), עדיין יוכל סימן מסחר להיחשב כבעל אופי מבחין
                        במידה והמבקש לרשום אותו יוכל
                         להוכיח כי הסימן "רכש" אופי מבחין
                         עקב השימוש שנעשה בו.  
                    </p>
                    <p>
                        סעיף 8(ב) לפקודת סימני המסחר מעניק לרשם או לבית המשפט סמכות לקבוע האם סימן
                        מסחר שהוגש לרישום הינו בעל אותו אופי מבחין נרכש.
                        המבחנים לבחינת קיומו של אופי מבחין נרכש מבוססים על נתונים עובדתיים שונים אשר
                        על מבקש הרישום להציג בפני מחלקת סימני המסחר, הרשם או בית המשפט. בין אותם נתונים
                        ניתן למנות את משך הזמן וההיקף שבו נעשה שימוש בסימן, האופן בו הוא פורסם,
                        האמצעים שהושקעו ביצירת קשר מחשבתי בין הסימן לתודעת הציבור לו הוא מיועד.
                        בנוסף, יש לקחת בחשבון האם אופי השימוש בסימן גרם לכך כי ציבור הצרכנים
                        מקשר בין המוצר נשוא הסימן לבין בעליו של הסימן.
                    </p>
                    <p>
                        כך למשל, אף אם מדובר בסימן תיאורי, הרי שככל שיובאו יותר נתונים ביחס לשיווקו
                        ופרסומו, כך יגבר הסיכוי כי הבקשה לרישומו תתקבל.
                    </p>

                    <p><b>
                        מבקשים לרשום סימן מסחר? מעוניינים לקבל מידע הנוגע לתנאי הכשרות לרישום סימן מסחר?
                        נשמח לעמוד לרשותכם בכל שאלה בנושא.
                    </b></p>

                    <a href="/תחומי עיסוק/סימני מסחר">חזרה לעמוד סימני מסחר</a>
                </>
            },
            { 
                "display": false,
                "id": "OCCUPATIONS-ONSPOT",
                "title": 'מהי בקשה לבחינה "על אתר"?', 
                "subtitle": "", 
                "link": "/תחומי עיסוק/בקשה לבחינה על אתר",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%91%D7%A7%D7%A9%D7%94%20%D7%9C%D7%91%D7%97%D7%99%D7%A0%D7%94%20%D7%A2%D7%9C%20%D7%90%D7%AA%D7%A8",
                "trajectory": ["תחומי עיסוק", "בקשה לבחינה על אתר"],
                "content": <>

                    <p>בקשה לבחינה על אתר הינה במילים אחרות, בקשה לזירוז הבחינה של בקשה לרישום סימן מסחר.</p>

                    <p>הליך רישום סימן מסחר מתחיל עם הגשתו של טופס בקשה, בצירוף אגרה מתאימה, למחלקת סימני המסחר, אשר
                    הינה חלק מרשות הפטנטים, סימני מסחר ועיצובים.</p>

                    <p>לאחר ההגשה, ממתינה הבקשה להליך בחינה שיתבצע על ידי אחד מבוחני מחלקת סימני המסחר. במסגרת זו
                    בודק הבוחן האם עומד הסימן המבוקש בכל הכללים הקבועים בפקודת סימני המסחר [נוסח חדש], תשל"ב-1972,
                    בחוזרי הרשם ובפסיקה, ולבסוף יקבע הבוחן האם הסימן המבוקש כשיר לרישום בפנקס סימני המסחר אם לאו.</p>

                    <p>נכון להיום, זמן ההמתנה הממוצע עד שמגיעה הבקשה לידיו של הבוחן לשם בחינתה כאמור, עומד על כ- 6 חודשים.</p>

                    <p>במקרים מסוימים, זמן המתנה זה יכול להיות קריטי עבור המבקש, ולפיכך על מנת לזרז את ההליך, עליו
                    לבקש ממחלקת סימני המסחר כי בקשתו לרישום הסימן תיבחן לאלתר.</p>

                    <p>העברת בקשה לרישום סימן מסחר ל"ראש התור" ובחינתה לפני בקשות אחרות הממתינות אף הן לבחינה, תיעשה
                    אך כאשר ישנה בידי המבקש סיבה מוצדקת לכך, כפי שנקבע בתקנה 22 (ג) לתקנות סימני המסחר, 1940.</p>

                    <p>בהוראות העבודה לבוחני מחלקת סימני מסחר, ניתן למצוא מספר דוגמאות לאותן "סיבות מוצדקות" לבחינת
                    בקשה על אתר, ביניהן למשל:</p>

                    <ul>
                        <li><span>כאשר נעשה שימוש בסימן המבוקש על ידי גורם שאינו מורשה לכך.</span></li>
                        <li><span>ישנה כוונה להגיש בקשה בינלאומית על בסיס הבקשה הלאומית.</span></li>
                        <li><span>הסימן הוגש בעבר ונתבקשו בו שינויים שלא ניתן היה לבצע במסגרת הבקשה הראשונה.</span></li>
                        <li><span>הסימן היה רשום בעבר ונמחק עקב טעות.</span></li>
                        <li><span>ישנה כוונה לתת בסימן רשות שימוש לאחרים.</span></li>
                    </ul>

                    <p>רשימת מקרים זו איננה רשימה סגורה, לפיכך על פי שיקול דעתה של מחלקת סימני מסחר, ייתכן כי נימוקים
                    אחרים ייראו כנסיבות מיוחדות ומוצדקות המאפשרות את קבלת הבקשה לזירוז הבחינה של סימן המסחר.</p>
                    
                    <p>על בקשה לזירוז הבחינה של סימן מסחר להיות מוגשת בצירוף תצהירו של המבקש, כשהוא ערוך וחתום כדין, ומאושר על ידי
                    עורך דין או עורך פטנטים הרשומים בישראל. על התצהיר להכיל את פירוט העובדות המשמשות יסוד לבקשה
                    ואת הסיבות והנימוקים המצדיקים את הקדמתה.</p>

                    <p>כמו כן יש לצרף לבקשה זו את האגרה הקבועה בתוספת הראשונה לתקנות סימני המסחר.</p>                    

                    <p><b>
                        מבקשים לרשום סימן מסחר? מעוניינים לקבל מידע הנוגע לתנאי הכשרות לרישום סימן מסחר?
                        נשמח לעמוד לרשותכם בכל שאלה בנושא.
                    </b></p>

                    <a href="/תחומי עיסוק/סימני מסחר">חזרה לעמוד סימני מסחר</a>
                </>
            },
            {
                "display": false,
                "id": "OCCUPATIONS-COMPETITIOPN",
                "title": 'בקשות מתחרות לרישום סימני מסחר', 
                "subtitle": "", 
                "link": "/תחומי עיסוק/בקשות מתחרות לרישום סימני מסחר",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%91%D7%A7%D7%A9%D7%95%D7%AA%20%D7%9E%D7%AA%D7%97%D7%A8%D7%95%D7%AA%20%D7%9C%D7%A8%D7%99%D7%A9%D7%95%D7%9D%20%D7%A1%D7%99%D7%9E%D7%A0%D7%99%20%D7%9E%D7%A1%D7%97%D7%A8",
                "trajectory": ["תחומי עיסוק", "בקשות מתחרות לרישום סימני מסחר"],
                "content": <>
                    <p>ראובן ושמעון, שניהם בעלי עסקים, הגישו כל אחד בנפרד, בקשה לרישום הלוגו שלהם כסימן מסחר. אלא
					שלמרבה הפתעתם, נדחו בקשותיהם על ידי בוחני מחלקת סימני המסחר. סיבת הדחייה בשני המקרים הייתה כי
					סימני המסחר שביקשו הינם דומים עד כדי להטעות.</p>

					<p>אכן, אחד מן העקרונות הבולטים העומדים בבסיס פקודת סימני המסחר הינו כי לא יירשם סימן מסחר זהה
					לסימן אחר או דומה לו באופן שיש בו כדי להטעות את הציבור.</p>

					<p>לפיכך, במצב בו הוגשו בקשות נפרדות לרישום סימני מסחר, על ידי אנשים שונים, כאשר הסימנים המבוקשים
					זהים או דומים עד כדי להטעות, לגבי אותם טובין או הגדר טובין, הרי שמחלקת סימני המסחר תסרב לקבל
					בקשות אלו.</p>

					<p>סיטואציה זו תיתכן כאשר הבקשות לרישום סימני המסחר הוגשו במקביל, או אחת אחרי השנייה, כאשר בחינתה
					של הבקשה הראשונה טרם הסתיימה.</p>

					<p>סעיף 29 לפקודת סימני מסחר עוסק בסוגיה זו, ועל פיו, בידם של ראובן ושמעון האפשרות להגיע להסכמה
					ביניהם בעניין זכויותיהם בנוגע לסימנים המבוקשים על ידם.  הסכמה מעין זו דורשת את אישורו של רשם
					סימני המסחר.</p>

					<p>במידה ולא הגיעו ראובן ושמעון להסכמה, ייפתח ביניהם הליך תחרות, אשר בסופו יכריע הרשם מי מבין
					הבקשות אשר הוגשו תעבור להמשך בחינה מהותית, ובקשתו של מי תידחה כבר בשלב זה.</p>

					<p>שלושה מבחני עזר עומדים לרשות הרשם לשם הכרעה בהליך התחרות:</p>

					<ol>
						<li><span><b><u>מועד הגשת הבקשה</u></b> - לא בהכרח מי שהגיש ראשון את בקשתו יזכה לעדיפות בהליך זה. עדיפות מטעם זה בלבד
						תינתן אך בנסיבות מיוחדות, ועל דרך הכלל, המשקל שיינתן למבחן זה הינו זניח.</span></li>

						<li><span><b><u>היקף השימוש בסימן המבוקש</u></b> - במסגרת זו ייבדק מי מבין המבקשים עשה שימוש בסימנו באופן נרחב יותר,
						ובהיקף כזה אשר גרם לציבור הצרכנים לקשר דווקא אותו עם הסימן המבוקש על ידו.</span></li>

						<li><span><b><u>תום הלב</u></b> - בשלב זה ייבחן תום ליבם של מגישי הבקשות המתחרות. תום הלב ייבחן לא רק במועד בחירת
						הסימן המבוקש, אלא אף לגבי אופן ההתנהלות עד להגשת הבקשה.<br />
						כך למשל במידה ואחד הצדדים בחר בסימן המבוקש על מנת להיבנות מהמוניטין השייך למבקש האחר, הרי שיש
						בכך כדי להעיד על חוסר תום לב אשר יביא לפסילת הבקשה.</span></li>
					</ol>

					<p>לפיכך, במקרה של ראובן ושמעון, לאחר שיכריע הרשם על פי המבחנים האמורים כי לבקשה אחת תינתן עדיפות
					על האחרת, רק היא זו אשר תמשיך לבחינה על ידי בוחני סימני המסחר, ואילו הבקשה האחרת לא תיבחן כלל.</p>

					<p>בנוסף יש להדגיש כי לאורך כל ההליך האמור לא נבחנים הסימנים המבוקשים לאור סעיפים 8 ו- 11 לפקודה,
					העוסקים בסוגית הכשירות להירשם כסימן מסחר. כך שבאופן תיאורטי, יכול להיווצר מצב בו סימן שאושר
					לבחינה לאחר הליך תחרות, לא יעמוד בתנאי הפקודה לעניין הכשירות, ולא יירשם בפנקס.</p>

					<p>על החלטת הרשם בעניין בקשות מתחרות ניתן להגיש ערעור לבית המשפט המחוזי בתוך 30 יום ממתן ההחלטה.</p>

                    <p><b>
                        מבקשים לרשום סימן מסחר? מעוניינים לקבל מידע הנוגע לתנאי הכשרות לרישום סימן מסחר?
                        נשמח לעמוד לרשותכם בכל שאלה בנושא.
                    </b></p>

                    <a href="/תחומי עיסוק/סימני מסחר">חזרה לעמוד סימני מסחר</a>
                </>
            },
            {
                "display": false,
                "id": "OCCUPATIONS-RENEW",
                "title": 'חידוש סימן מסחר רשום', 
                "subtitle": "", 
                "link": "/תחומי עיסוק/חידוש סימן מסחר רשום",
                "icon": "divorce-agreement",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%97%D7%99%D7%93%D7%95%D7%A9%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8%20%D7%A8%D7%A9%D7%95%D7%9D",
                "trajectory": ["תחומי עיסוק", "חידוש סימן מסחר רשום"],
                "content": <>
                    <p>על פי הקבוע בפקודת סימני מסחר [נוסח חדש], תשל"ב-1972, תוקפו של סימן מסחר רשום הוא עשר שנים.
                    יצוין כי הספירה מתחילה מיום הגשת הבקשה לרישום הסימן ולא מיום רישומו בפנקס סימני המסחר.</p>

                    <p>בחלוף התקופה, קיימת אפשרות לחידוש הרישום לעשר שנים נוספות בכל פעם, ללא הגבלת מספר הפעמים בהן
                    ניתן לחדש את הרישום, כך שהסימן יוכל באופן עקרוני להיות רשום ללא הגבלת זמן. זאת לעומת הגנות
                    אחרות בקניין רוחני המחייבות רישום, כגון פטנט או עיצוב המוגבלות בזמן.</p>

                    <p>בתקנות סימני המסחר-1940 מפורט האופן בו יתבצע חידוש רישום סימן המסחר, אופן הגשת הבקשה והאגרה
                    אותה יש לשלם בגין החידוש.</p>

                    <p>במידה ובעל סימן מסחר רשום מעוניין לחדש את רישום הסימן בפנקס (בחלוף 10 שנים מיום הגשתו), עליו,
                    או על אדם הפועל מטעמו, להגיש למחלקת סימני המסחר ברשות הפטנטים, סימני מסחר ומדגמים, בקשה מתאימה
                    לחידוש הרישום.</p>

                    <p>על הבקשה להיות מוגשת במהלך שלושת החודשים שלפני תום מועד הרישום האחרון של סימן המסחר, ויש לצרף
                    לה העתק קבלה על תשלום אגרת החידוש הקבועה בתקנות.</p>

                    <p>הודעה על חידושו של הרישום תישלח לבעל סימן המסחר, והחידוש יפורסם באתר האינטרנט של רשות סימני המסחר.</p>

                    <p><b><u>לא הוגשה בקשה לחידוש רישום סימן המסחר במועד</u></b>: במקרה כזה תישלח הודעה על כך לבעל הסימן מטעם
                    מחלקת סימני המסחר. הודעה זו תישלח לא פחות מחודש אחד ולא יותר משני חדשים לפני גמר מועדו של
                    הרישום האחרון של הסימן המסחרי. אולם, יודגש כי הפקודה מטילה על בעל סימן המסחר את האחריות להודיע
                    כי ברצונו להמשיך ולקבל הגנה על סימנו הרשום. כך שאי משלוח הודעה מטעם מחלקת סימני המסחר על פקיעת
                    תוקף הרישום איננה גורעת מחובתו של בעל הסימן להודיע כי הינו מעוניין בהארכת תוקף ההגנה.</p>

                    <p>במידה ובגמר מועדו של הרישום האחרון של סימן המסחר לא שולמה אגרת החידוש, יפרסם הרשם את הדבר מיד
                    באתר האינטרנט, ואם בתוך ששה חדשים מיום פרסום המודעה נתקבלה בקשה לחידוש בצירוף קבלה על תשלום
                    אגרת החידוש ואגרת הפיגור הקבועה, רשאי הרשם לחדש את הרישום בלי למחוק את הסימן מן הפנקס.</p>

                    <p>במידה ועברו ששה חדשים מיום פרסום המודעה, ועדיין לא שולמה האגרה, רשאי הרשם למחוק את הסימן מן
                    הפנקס החל מיום גמר מועד הרישום האחרון.</p>

                    <p>במידה ושולמו לאחר מכן אגרת החידוש וכן אגרת החזרה לתוקף, רשאי הרשם לחזור ולרשום את הסימן בפנקס
                    אם נוכח כי מן הישר לעשות כן בתנאים שימצא לנכון להטיל, ובלבד שהתבקש לעשות כן בתוך שנים עשר
                    חודשים מיום גמר מועד הרישום האחרון.</p>

                    <p>אם נמחק סימן מסחר מן הפנקס, תוכנס הערה בפנקס סימני המסחר על המחיקה וסיבתה.</p>

                    <p>יצוין כי סימן מסחר שנמחק מפני שלא שולמה אגרת חידוש, יראוהו בכל זאת, במשך שנה לאחר המחיקה,
                    כאילו הוא סימן מסחר רשום לעניין כל בקשה לרישום.​</p>

                    <p><b>
                        מבקשים לרשום סימן מסחר? מעוניינים לקבל מידע הנוגע לתנאי הכשרות לרישום סימן מסחר?
                        נשמח לעמוד לרשותכם בכל שאלה בנושא.
                    </b></p>

                    <a href="/תחומי עיסוק/סימני מסחר">חזרה לעמוד סימני מסחר</a>
                </>
            },
            {
                "display": true,
                "id": "OCCUPATIONS-TRADEMARKS-INT",
                "title": "סימני מסחר בינלאומיים", 
                "subtitle": "ההגנה על סימן מסחר הינה טריטוריאלית בלבד, לפיכך, על מנת להגן על סימן המסחר מחוץ לישראל יש לרשמו בכל מדינה ומדינה בה מבוקשת ההגנה על הסימן.", 
                "link": "/תחומי עיסוק/סימני מסחר בינלאומיים",
                "icon": "envonmental-law",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%A1%D7%99%D7%9E%D7%A0%D7%99%20%D7%9E%D7%A1%D7%97%D7%A8%20%D7%91%D7%99%D7%A0%D7%9C%D7%90%D7%95%D7%9E%D7%99%D7%99%D7%9D",
                "trajectory": ["תחומי עיסוק", "סימני מסחר בינלאומיים"],
                "content": 
                    <>
                        <p>
                            ההגנה על סימן מסחר הינה טריטוריאלית בלבד, לפיכך, על מנת להגן על סימן
                            המסחר מחוץ לישראל יש לרשמו בכל מדינה ומדינה בה מבוקשת ההגנה על הסימן.
                        </p>

                        <p>
                            דרך יעילה לרישום סימן במדינות שונות יכולה להתבצע באמצעות הגשת בקשה לרישום סימן בינלאומי
                            על פי פרוטוקול מדריד אשר מדינת ישראל חברה בו. במסגרת הבקשה ניתן לייעד למעלה מ- 90 מדינות 
                            שונות החברות אף הן בפרוטוקול.
                        </p>

                        <p>
                            ניתן להגיש בקשה לרישום סימן מסחר בינלאומי באמצעות מחלקת סימני המסחר בישראל,
                            וזאת על בסיס בקשה לרישום סימן מסחר או סימן רשום בישראל. במידה ולאחר
                            הגשת הבקשה הבינלאומית מתעורר הצורך להגן על הסימן במדינה נוספת ניתן
                            לעשות זאת באמצעות הגשת בקשת הרחבה.
                        </p>

                        <p>
                            משרדנו מטפל בהגשת בקשות לרישום סימני מסחר בינלאומיים וטיפול שוטף בבקשות
                             מול הארגון העולמי לקניין רוחני – <a href="http://www.wipo.int/" target="_blank">WIPO</a>.
                        </p>
                    </>
            },
            {   
                "display": true,
                "id": "OCCUPATIONS-COPYRIGHT",
                "title": "זכויות יוצרים", 
                "subtitle": "זכות יוצרים היא זכות בקניין רוחני אשר בניגוד לזכויות הקניין הרוחני התעשייתי אינה דורשת רישום. זכות זו נועדה להגן על יוצר או בעלים של יצירה מפני העתקתה או עשיית שימוש בה ללא רשות על ידי אחרים.", 
                "link": "/תחומי עיסוק/זכויות יוצרים",
                "icon": "inheritance-law",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%96%D7%9B%D7%95%D7%99%D7%95%D7%AA%20%D7%99%D7%95%D7%A6%D7%A8%D7%99%D7%9D",
                "trajectory": ["תחומי עיסוק", "זכויות יוצרים"],
                "content": 
                    <>
                        <p>
                            זכות יוצרים היא זכות בקניין רוחני אשר בניגוד לזכויות הקניין הרוחני התעשייתי אינה
                            דורשת רישום. זכות זו נועדה להגן על יוצר או בעלים של יצירה מפני העתקתה או עשיית
                            שימוש בה ללא רשות על ידי אחרים.
                        </p>

                        <p>
                            בחוק זכות יוצרים, תשס"ח – 2007, מפורטות הדרישות אשר בהתקיימן תחול ההגנה: על היצירה
                            להיות אחת מסוג היצירות המנויות בחוק, דהיינו, עליה להיות יצירה ספרותית, אמנותית,
                            דרמטית או מוסיקלית. בנוסף, קיימים סוגי יצירות נוספים אשר יזכו להגנה זו, כגון
                            יצירה אדריכלית, יצירת פיסול, יצירת צילום ויצירה קולנועית. כמו כן, על היצירה
                            להיות מקורית ומקובעת באופן פיזי.
                        </p>

                        <p>
                            כאשר מדובר בזכות יוצרים ביצירה, ניתן להפרידה לשני חלקים. החלק
                            האחד מתייחס לזכויות הכלכליות המונחות בבסיסה של היצירה, 
                            דהיינו, האפשרות להעביר את הבעלות בה ואף לסחור בה, ואילו החלק השני מתייחס לזכות 
                            המוסרית אשר אינה ניתנת להעברה. במסגרת הזכות המוסרית קיימת ליוצר הזכות
                            לדרוש כי היצירה תיקרא על שמו (מתן קרדיט) וכן כי לא יוטל פגם או סילוף ביצירתו,
                            וזאת אף אם הזכות הכלכלית אינה בידיו.
                        </p>

                        <p>
                            זכות יוצרים ביצירה הינה למשך כל חייו של היוצר ועד 70 שנה לאחר מותו, 
                            ובמקרה של יצירה משותפת, 70 שנה לאחר מותו של היוצר האחרון.
                        </p>

                        <p>
                            על פי החוק, במקרה של הפרת זכות יוצרים ביצירה, ניתן לתבוע פיצויים בלא הוכחת
                            נזק בסכום של עד ₪100,000 בשל כל הפרה.
                        </p>
                    </>
            },
            {   
                "display": true,
                "id": "OCCUPATIONS-DESIGNS",
                "title": "עיצובים", 
                "subtitle": "עיצוב רשום הינו זכות קניינית המעניקה הגנה על העיצוב החזותי והאסתטי של מוצר המיועד לשימוש תעשייתי.", 
                "link": "/תחומי עיסוק/עיצובים",
                "icon": "real-estate-law",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%A2%D7%99%D7%A6%D7%95%D7%91%D7%99%D7%9D",
                "trajectory": ["תחומי עיסוק", "עיצובים"],
                "content": 
                    <>
                        <p>עיצוב רשום הינו זכות קניינית המעניקה הגנה על העיצוב החזותי והאסתטי של מוצר המיועד לשימוש תעשייתי. חוק העיצובים החדש שנכנס לתוקף ביום 7.8.2018, בא למעשה להחליף את ההסדר שהיה קיים עד אז בעניין זה במסגרת פקודת הפטנטים והמדגמים.</p>
                        
                        <p>הגדרת המונח "מדגם" שבפקודה הישנה הוחלפה בהגדרה "עיצוב". שינוי המונח כשלעצמו אינו מצמצם או מרחיב את משמעות ההגנה לפי החוק החדש, אלא משקף את ההתפתחויות הטכנולוגיות שחלו במהלך השנים.</p>
                        
                        <p>על פי חוק העיצובים, "עיצוב" מוגדר כמראהו של מוצר, או חלק ממוצר, המורכב ממאפיין חזותי אחד או יותר של המוצר או של חלק מהמוצר, לפי העניין, ובכלל זה מיתאר, צבע, צורה, עיטור, מרקם או החומר שממנו הם עשויים.</p>

                        <p>ניתן להגיש בקשות לרישום עיצובים בגין מוצרים שונים כגון, ביגוד, הנעלה, תכשיטים, מוצרים לבית ועוד, כמו גם מוצרים המהווים מערכת של פריטים מאותו סוג, שהם בעלי מאפיינים חזותיים הנבדלים זה מזה רק בפרטים שאינם מהותיים, ובאופן רגיל הם מוצעים למכירה או שימוש יחד, לדוגמא, מערכת ישיבה או מערכת כלי אוכל. החוק החדש מאפשר לרשום גם סימנים גרפיים (Graphic Symbol - icon) ותצוגות מסך כעיצובים, אך אינו מאפשר רישום של גופנים או תוכנות מחשב, כמו כן לא יירשמו לוגואים העשויים להיות מוגנים במסגרת דינים אחרים כגון סימני מסחר או זכויות יוצרים.</p>

                        <p>
                            עיצוב יחשב ככשיר לרישום במידה והוא עומד בתנאים הקבועים בחוק, דהיינו, עליו להיות חדש, 
                            בעל אופי ייחודי והוא אינו פוגע בתקנת הציבור. במסגרת חוק העיצובים נקבע כי פרסום העיצוב
                            ב- 12 החודשים שלפני המועד הקובע בידי בעל העיצוב או כתוצאה ממידע שהגיע מבעל העיצוב, לא 
                            יהיה בו כדי לשלול את החידוש של העיצוב. למעשה, באמצעות סעיף זה, מעניק המחוקק "תקופת חסד" 
                            במהלכה יכול בעל העיצוב לבדוק את השוק, לגייס מימון למטרת ייצור העיצוב, וכדו'. אולם יודגש
                            כי תקופת חסד זו היא רק במקרה בו הפרסום האמור מקורו בבעל העיצוב עצמו ולא במקור אחר.
                        </p>

                        <p>
                            החוק החדש מאפשר הגנה על עיצובים רשומים וכן על כאלה שאינם, כאשר ההבדל 
                            ביניהם הוא בתקופת ההגנה ובהיקף ההגנה שתהיה לכל אחד מהם. עיצוב רשום היא
                            עיצוב שעבר את הבחינה במחלקת העיצובים ונרשם בפנקס העיצובים. תקופת ההגנה
                            על עיצוב רשום הינה למשך חמש שנים, כאשר ניתן לחדש את הרישום לארבע תקופות
                            נוספות של חמש שנים כל אחת (בכפוף לתשלום אגרת חידוש) כך שסך כל תקופת
                            ההגנה האפשרית באמצעות עיצוב רשום הינה 25 שנים, ללא אפשרות הארכה נוספת.
                        </p>

                        <p>
                            לעומת זאת, תקופת ההגנה על עיצוב שאינו 
                            רשום תהיה למשך 3 שנים בלבד. יחד עם זאת, כאמור, בעל עיצוב לא רשום יהיה רשאי,
                            בתוך 12 חודשים מהמועד הקובע, להגיש בקשה לרישומו של העיצוב הלא רשום.
                        </p>

                        <p>משרדנו מטפל בהגשת בקשות לרישום עיצובים וטיפול שוטף בבקשות מול מחלקת העיצובים ובמקרה הצורך גם בפני בית הדין של רשות הפטנטים, סימני המסחר ומגדמים, וכן בפני בתי המשפט.</p>
                    </>
            },
            {
                "display": true,
                "id": "OCCUPATIONS-PLAGIARISM",
                "title": "גניבת עין",
                "subtitle": "עוולת גניבת עין נועדה להגן על יצרנים מפני פגיעה במוניטין שנרכש על ידם. לעוולה זו שני יסודות מצטברים אשר בהתקיימם יהיה זכאי יצרן לפיצוי מאת מתחריו.", 
                "link": "/תחומי עיסוק/גניבת עין",
                "icon": "adminstrative-law",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%92%D7%A0%D7%99%D7%91%D7%AA%20%D7%A2%D7%99%D7%9F",
                "trajectory": ["תחומי עיסוק", "גניבת עין"],
                "content":
                    <>
                        <p>עוולת גניבת עין קבועה בסעיף 1(א) לחוק עוולות מסחריות, התשנ"ט-1999, והיא נועדה להגן על יצרנים מפני פגיעה במוניטין שנרכש על ידם.</p>

                        <p>לעוולה זו שני יסודות מצטברים אשר בהתקיימם יהיה זכאי יצרן לפיצוי מאת מתחריו.</p>

                        <p>
                            היסוד האחד הוא קיום של מוניטין בנכס או בשירות של היצרן.
                            המושג "מוניטין" מכוון לתדמיתו החיובית של מוצר
                            בעיני הציבור, אשר הודות לה קיים שוק של לקוחות אשר מעוניינים ברכישתו של המוצר.
                        </p>

                        <p>היסוד השני הוא חשש סביר להטעיה של הציבור כי השירות או הנכס שמציע המתחרה הם למעשה נכס או שירות של היצרן המקורי.</p>

                        <p>על פי החוק, ניתן לתבוע פיצויים בשל עוולת גניבת עין בלא הוכחת נזק בסכום של עד ₪100,000.</p>
                    </>
            },
            {
                "display": true,
                "id": "OCCUPATIONS-STEALING",
                "title": "גזל סוד מסחרי", 
                "subtitle": "עוולת גזל סוד מסחרי נועדה במרבית המקרים להגן על מעסיקים מפני עובדים או עובדים לשעבר אשר בעת עזיבתם נוטלים עימם סודות מסחריים השייכים למעסיקים וכן כהגנה עבור יצרנים מפני מתחרים.", 
                "link": "/תחומי עיסוק/גזל סוד מסחרי",
                "icon": "intellectual-property",
                "path": "/%D7%AA%D7%97%D7%95%D7%9E%D7%99%20%D7%A2%D7%99%D7%A1%D7%95%D7%A7/%D7%92%D7%96%D7%9C%20%D7%A1%D7%95%D7%93%20%D7%9E%D7%A1%D7%97%D7%A8%D7%99",
                "trajectory": ["תחומי עיסוק", "גזל סוד מסחרי"],
                "content":
                    <>
                        <p>עוולת גזל סוד מסחרי קבועה בחוק עוולות מסחריות, התשנ"ט-1999. עוולה זו נועדה במרבית המקרים להגן על מעסיקים מפני עובדים או עובדים לשעבר אשר בעת עזיבתם נוטלים עימם סודות מסחריים השייכים למעסיקים וכן כהגנה עבור יצרנים מפני מתחרים.</p>

                        <p>החוק אף קובע כי בכל הנוגע לגזל סוד מסחרי בידי עובד או עובד לשעבר, הסמכות הייחודית קנויה לבית הדין לעבודה.</p>

                        <p>סוד מסחרי מוגדר בחוק כ"מידע עסקי, מכל סוג, שאינו נחלת הרבים ושאינו ניתן לגילוי כדין בנקל על ידי אחרים, אשר סודיותו מקנה לבעליו יתרון עסקי על פני מתחריו, ובלבד שבעליו נוקט אמצעים סבירים לשמור על סודיותו". הגנה זו יכולה לחול לדוגמה על: תוכנה, פורמולה, נוסחה, רשימת לקוחות, תהליך ייצור וכו'.</p>

                        <p>על מנת לזכות בתביעה בגין עוולה זו, על התובע להוכיח את התקיימותם של שני יסודות. ראשית יש צורך להוכיח כי אכן קיים סוד מסחרי, לאחר מכן יש להוכיח כי הוא נגזל באחת מן הדרכים הבאות:</p>

                        <ol>
                            <li>נטילת סוד מסחרי ללא הסכמת בעליו באמצעים פסולים, או שימוש בסוד על ידי הנוטל.</li>
                            <li>שימוש בסוד מסחרי ללא הסכמת בעליו כאשר השימוש הוא בניגוד לחיוב חוזי או לחובת אמון, המוטלים על המשתמש כלפי בעל הסוד.</li>
                            <li>קבלת סוד מסחרי או שימוש בו ללא הסכמת בעליו, כאשר המקבל או המשתמש יודע או שהדבר גלוי על פניו, בעת הקבלה או השימוש, כי הסוד הועבר אליו באופן האסור שלעיל, או כי הסוד הועבר אל אדם אחר כלשהו באופן אסור כאמור לפני שהגיע אליו.</li>
                        </ol>

                        <p>על פי החוק, ניתן לתבוע פיצויים בשל עוולת גזל סוד מסחרי בלא הוכחת נזק בסכום של עד ₪100,000.</p>
                        </>
            }
        ]
    },
    {
        "id": "ABOUT",
        "title": "אודות",
        "link": "/אודות",
        "path": "/%D7%90%D7%95%D7%93%D7%95%D7%AA",
        "trajectory": ["אודות"]
    },
    {
        "id": "QNA",
        "title": "שאלות נפוצות",
        "link": "/שאלות נפוצות",
        "path": "/%D7%A9%D7%90%D7%9C%D7%95%D7%AA%20%D7%A0%D7%A4%D7%95%D7%A6%D7%95%D7%AA",
        "trajectory": ["שאלות נפוצות"],
        "submenu": [
            {
                "display": false,
                "id": "QNA1",
                "top": 500,
                "title": "מה זה סימן מסחר?", 
                "subtitle": `   סימן מסחר הוא סימן המזוהה עם מוצרים או שירותים של גוף כלשהו (למשל, אדם פרטי או חברה).
                                סימן מסחר יכול להיות מורכב מאותיות, ספרות, מילים, דמויות, סמלים, לוגו, או אותות אחרים.
                                סימן מסחר יכול להיות גם תלת ממדי.`, 
                "link": "/שאלות נפוצות/סימני מסחר",
                "path": "/%D7%A9%D7%90%D7%9C%D7%95%D7%AA%20%D7%A0%D7%A4%D7%95%D7%A6%D7%95%D7%AA/%D7%9E%D7%94%20%D7%96%D7%94%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8",
                "trajectory": ["שאלות נפוצות", "מה זה סימן מסחר?"],
                "content": <>
                    <p>
                        סימן מסחר הוא סימן המזוהה עם מוצרים או שירותים של גוף כלשהו (למשל, אדם פרטי או חברה).
                        סימן מסחר יכול להיות מורכב מאותיות, ספרות, מילים, דמויות, סמלים, לוגו, או אותות אחרים.
                        סימן מסחר יכול להיות גם תלת ממדי.
                    </p>

                    <p>
                        רישום סימן מסחר מעניק לבעליו זכות קניינית המאפשרת לו לבדל את הסחורות או השירותים 
                        שלו ממוצרים או שירותים של מתחרים, ולגרום לציבור הצרכנים לזהות אותם אתו בלבד.
                    </p>

                    <p>
                        הדינים החלים על סימני המסחר בישראל מקורם בפקודת סימני מסחר [נוסח חדש], תשל"ב-1972.
                        לא כל סימן ניתן לרישום באופן אוטומטי, אלא עליו לעמוד במספר כללים ומבחני עזר שנקבעו בפקודה
                        ובפסיקות בתי המשפט. למשל, על סימן מסחר להיות בעל אופי מבחין מולד או בעל אופי מבחין נרכש,
                        בעקבות השימוש שנעשה בו. דוגמאות נוספות לסימנים אשר אינם כשירים לרישום בישראל: סימן המרמז
                        על קשר עם נשיא המדינה, דגלי המדינה או סמליה; סימן שמטעה את הציבור, סימן הזהה או דומה עד כדי 
                        הטעיה לסימן מסחר רשום אחר, ועוד.
                    </p>
                </>
            },
            {
                "display": false,
                "id": "QNA2",
                "top": 555,
                "title": "איך רושמים סימן מסחר?", 
                "subtitle": `לפני שמתחילים בהליך הרישום, מומלץ לבצע חיפוש מקדים על מנת לבדוק האם הסימן אותו מבקשים לרשום
                            אינו רשום כבר כסימן מסחר אחר או אם קיים סימן הדומה לו עד כדי הטעיה. את החיפוש
                            ניתן לבצע באמצעות מאגרי מידע ציבוריים ומאגרי מידע בתשלום.`, 
                "link": "/שאלות נפוצות/סימני מסחר בינלאומיים",
                "path": "/%D7%A9%D7%90%D7%9C%D7%95%D7%AA%20%D7%A0%D7%A4%D7%95%D7%A6%D7%95%D7%AA/%D7%90%D7%99%D7%9A%20%D7%A8%D7%95%D7%A9%D7%9E%D7%99%D7%9D%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8",
                "trajectory": ["שאלות נפוצות", "איך רושמים סימן מסחר?"],
                "content": <>
                        <p>
                            לפני שמתחילים בהליך הרישום, מומלץ לבצע חיפוש מקדים על מנת לבדוק האם הסימן אותו מבקשים לרשום
                            אינו רשום כבר כסימן מסחר אחר או אם קיים סימן הדומה לו עד כדי הטעיה. את החיפוש
                            ניתן לבצע באמצעות מאגרי מידע ציבוריים ומאגרי מידע בתשלום.
                        </p>

                        <p>
                            בנוסף, יש לקבוע כמה סוגים יכללו בבקשה ולנסח את פרטת הסחורות והשירותים במסגרת כל סוג.
                            לדוגמא, יצרן מתחום המזון עשוי להגיש בקשה לרישום סימן המסחר שלו בסוג 29 לגבי יוגורט ומוצרי
                            חלב אחרים, ובסוג 30 לגבי גלידות. יצוין כי לאחר הגשת הבקשה לא ניתן להוסיף לה סוגים אלא
                            יש להגיש בקשה נוספת, בנפרד מהבקשה המקורית.
                        </p>                        

                        <p>
                            הליך רישום סימן מסחר בישראל מורכב מכמה שלבים: 
                        </p>

                        <ol>
                            <li>
                                <u>הגשת בקשה לרישום סימן מסחר</u> – את הבקשה ניתן להגיש באמצעות המערכת 
                                המקוונת של מחלקת סימני המסחר. בעת ההגשה יש למלא את פרטי מבקש הרישום, להזין את פרטי הסימן
                                המבוקש ואת פרטת הסחורות או השירותים להם ישמש הסימן. במידה ומדובר בסימן מסחר
                                שהוא לוגו (מעוצב) יש לצרף גם תמונה של הסימן בפורמט JPEG. בסיום הליך הרישום יש לשלם את אגרה.
                            </li>

                            <li>
                                <u>בחינת הבקשה לרישום סימן המסחר</u> – לאחר ההגשה מועברת הבקשה
                                לבחינה על ידי אחד מבוחני מחלקת סימני המסחר שייקבע האם הסימן
                                עומד בכל הכללים הקבועים בפקודת סימני המסחר, בחוזרי הרשם ובפסיקה, 
                                והאם הוא כשיר לרישום. לדוגמא, הבוחן יבדוק האם הסימן המבוקש אינו מתאר 
                                את הסחורות והשירותים הכלולים בבקשה והאם הוא אינו זהה או דומה עד כדי הטעיה
                                לסימנים רשומים אחרים. במידה והבוחן יימצא כי הסימן המבוקש אינו כשיר לרישום,
                                הוא ינפיק דו"ח בחינה עליו ניתן לענות בתוך שלושה חודשים.
                                <br/><br/>

                                <u>זירוז הבחינה</u> -  זמן ההמתנה מיום הגשת הבקשה ועד שהיא מועברת לבחינה על
                                ידי בוחן עומד כיום על כ- 6 חודשים.
                                <br/>

                                במקרים מסוימים, זמן ההמתנה לבחינה יכול להיות קריטי עבור המבקש.
                                לפיכך, על מנת לזרז את ההליך, ניתן לבקש ממחלקת סימני המסחר כי הבקשה
                                לרישום הסימן תיבחן לאלתר. העברת בקשה לרישום סימן מסחר ל"ראש התור"
                                ובחינתה לפני בקשות אחרות הממתינות אף הן לבחינה, תיעשה כאשר
                                יש בידי המבקש סיבה מוצדקת לכך. הליך זה כרוך בתשלום אגרה נוספת ובהגשת תצהיר.
                            </li>

                            <li>
                                <u>קיבול הבקשה ופרסום ביומן סימני המסחר</u> – במידה והבוחן סבור כי הסימן כשיר לרישום 
                                (בין אם לא הוצאו דו"חות בחינה כלל ובין אם הוצאו דו"חות אשר המבקש הצליח לענות
                                עליהם ולשכנע את הבוחן), ישלח הבוחן למבקש מכתב קבלה בו הוא מודיע על קיבול
                                הסימן לרישום ועל פרסום הבקשה להתנגדויות ביומן סימני המסחר הקרוב.
                            </li>

                            <li>
                                <u>הגשת התנגדות לרישום סימן מסחר</u> – על פי פקודת סימני המסחר, בתוך 
                                שלושה חדשים מיום הפרסום, רשאי כל אדם להגיש לרשם הודעה על התנגדותו לרישום הסימן.
                                הגשת התנגדות עשויה להיות במקרים בהם סבור המתנגד כי קיימת סיבה ולפיה 
                                מוסמך הרשם לסרב לבקשת הרישום, למשל דמיון עד כדי הטעיה לסימן אחר.
                                עילה נוספת להגשת התנגדות היא טענת המתנגד לבעלות על הסימן המבוקש. 
                                <br/>
                                
                                הליך ההתנגדות מתנהל בפני בית הדין של רשות סימני המסחר, במסגרתו מגישים הצדדים
                                כתבי טענות וראיות, וכן מתקיים דיון בפני הרשם הכולל חקירת המצהירים. 
                            </li>

                            <li>
                                <u>רישום סימן המסחר בפנקס</u> – במידה ולא הוגשו התנגדויות לרישום הסימן,
                                או אם עלה בידי מבקש הסימן להתגבר על התנגדויות שהוגשו, יירשם הסימן בפנקס סימני 
                                המסחר. ההגנה המוענקת לסימן מסחר רשום תקפה לעשר שנים, אשר בסיומן ניתן לחדש
                                את הסימן בכל פעם לעשר שנים נוספות, בכפוף לתשלום האגרה המתאימה.
                            </li>
                        </ol>
                </>
            },
            {
                "display": false,
                "id": "QNA3",
                "top": 615,
                "title": "כמה עולה לרשום סימן מסחר?", 
                "subtitle": `   נכון לשנת 2024, הגשת בקשה לרישום סימן
                                מסחר עבור סוג אחד של טובין או שירותים כרוכה בתשלום אגרת בסיס של 1,795 ₪.
                                עבור כל סוג נוסף במסגרת אותה בקשה יש לשלם אגרה נוספת של 1,350 ₪.

`, 
                "link": "/שאלות נפוצות/כמה עולה לרשום סימן מסחר",
                "path": "/%D7%A9%D7%90%D7%9C%D7%95%D7%AA%20%D7%A0%D7%A4%D7%95%D7%A6%D7%95%D7%AA/%D7%9B%D7%9E%D7%94%20%D7%A2%D7%95%D7%9C%D7%94%20%D7%9C%D7%A8%D7%A9%D7%95%D7%9D%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8",
                "trajectory": ["שאלות נפוצות", "כמה עולה לרשום סימן מסחר?"],
                "content": <>
                        <p>
                            נכון לשנת 2024, הגשת בקשה לרישום סימן מסחר עבור סוג אחד של טובין או שירותים
                            כרוכה בתשלום אגרת בסיס של 1,795 ₪. עבור כל סוג נוסף במסגרת אותה בקשה יש לשלם
                            אגרה נוספת של 1,350 ₪.
                        </p>
                        
                        <p>
                            במידה ומוגשת בקשה לזירוז הבחינה, יש לצרף אגרה נוספת בסך של 842 ₪ עבור כל סוג
                            טובין במסגרת אותה בקשה.
                        </p>
                        
                        <p>
                            האגרות משולמות ישירות למחלקת סימני המסחר ואינן כוללות שכר טרחת עורך דין.
                        </p>
                        
                        <p>
                            ניתן להגיש באמצעות משרדנו בקשות לרישום סימני מסחר וכן לקבל ליווי שוטף בהליך
                            הרישום המתנהל מול מחלקת סימני המסחר ובית הדין. כמו כן, אנו מטפלים בהגשת בקשות
                            לזירוז הבחינה לאחר הגשת הבקשה לרישום, וכן בהכנת מענים לדו"חות בחינה שנשלחו על
                            ידי בוחני מחלקת סימני המסחר.
                        </p>
                </>
            },
            {
                "display": false,
                "id": "QNA4",
                "top": 675,
                "title": "האם אפשר לבטל את סימן המסחר לאחר הרישום?", 
                "subtitle": "ניתן לבטל או למחוק רישום של סימן מסחר במקרים שונים, כאשר שני ההליכים העיקריים הם:", 
                "link": "/שאלות נפוצות/האם אפשר לבטל את סימן המסחר לאחר הרישום",
                "path": "/%D7%A9%D7%90%D7%9C%D7%95%D7%AA%20%D7%A0%D7%A4%D7%95%D7%A6%D7%95%D7%AA/%D7%94%D7%90%D7%9D%20%D7%90%D7%A4%D7%A9%D7%A8%20%D7%9C%D7%91%D7%98%D7%9C%20%D7%90%D7%AA%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%94%D7%9E%D7%A1%D7%97%D7%A8%20%D7%9C%D7%90%D7%97%D7%A8%20%D7%94%D7%A8%D7%99%D7%A9%D7%95%D7%9D",
                "trajectory": ["שאלות נפוצות", "האם אפשר לבטל את סימן המסחר לאחר הרישום?"],
                "content": <>
                        <p>
                            ניתן לבטל או למחוק רישום של סימן מסחר במקרים שונים, כאשר שני ההליכים העיקריים הם: 
                        </p>
                        
                        <p>
                            <u>בקשה לביטול סימן מסחר בשל חוסר שימוש:</u> ניתן להגיש לרשם סימני המסחר בקשה לביטול סימן
                            מסחר רשום, במסגרתה יש להוכיח כי לא נעשה שימוש בסימן הרשום במשך שלוש השנים שקדמו
                            להגשת הבקשה. הליך זה מתנהל מול בית הדין של רשות סימני המסחר, כשבמסגרת זו מוגשים כתבי
                            טענות וראיות מטעם מבקש הביטול ומטעם בעל הסימן הרשום, לאחר מכן מתקיים דיון שלאחריו
                            יחליט הרשם האם לקבל את הבקשה ולבטל את רישום הסימן, או לדחות את הבקשה ולהשאיר את הסימן רשום.
                        </p>
                        
                        <p>
                            <u>בקשה למחיקת סימן מסחר:</u> ניתן להגיש לרשם בקשה למחיקת סימן מסחר רשום במקרים בהם מגיש
                            הבקשה סבור כי הסימן לא היה כשיר לרישום מלכתחילה, או אם הסימן הרשום יוצר תחרות
                            בלתי הוגנת עם זכויות השייכות לו. הליך זה מתנהל מול בית הדין של רשות סימני המסחר,
                            כשבמסגרת זו מוגשים כתבי טענות וראיות מטעם מבקש המחיקה ומטעם בעל הסימן הרשום,
                            לאחר מכן מתקיים דיון שלאחריו יחליט הרשם האם לקבל את הבקשה ולמחוק את הסימן,
                            או לדחות את הבקשה ולהשאיר את הסימן רשום.
                        </p>
                        
                        <p>
                            שימו לב! בקשה למחיקת סימן רשום ניתן להגיש בתוך <b>חמש שנים</b> מיום רישום הסימן. יחד עם זאת,
                            בקשה למחיקת סימן מסחר בשל כך שהבקשה לרישום הסימן הוגשה שלא בתום לב, ניתן להגיש בכל עת,
                            וללא הגבלת זמן.
                        </p>
                </>
            },
        ]
    },
    {
        "id": "CONTACTUS",
        "title": "צור קשר",
        "link": "/צור קשר",
        "path": "/%D7%A6%D7%95%D7%A8%20%D7%A7%D7%A9%D7%A8",
        "trajectory": ["צור קשר"]
    },
    {
        "id": "ACCESSIBILITY",
        "title": "הצהרת נגישות",
        "link": "/הצהרת נגישות",
        "path": "/%D7%94%D7%A6%D7%94%D7%A8%D7%AA%20%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA",
        "trajectory": ["הצהרת נגישות"],
        "display": false
    },    
];


export function GetMenu() {
    return MenuTree.filter(si => si.display === undefined || si.display);
};
export function GetOccupationsList() {
    return MenuTree.find(m => { return m.id === "OCCUPATIONS" }).submenu;
};
export function GetQnAList() {
    return MenuTree.find(m => { return m.id === "QNA" }).submenu;
};
export function GetMenuItem(pathname) {
    const menu = MenuTree.find(m => { return m.path === pathname });
    if (menu) return menu;

    const hassubmenu = MenuTree.filter(m => { return m.submenu !== undefined });

    for (let i=0; i<hassubmenu.length; i++) {
        const submenu = hassubmenu[i].submenu.find(m => { return m.path === pathname });
        if (submenu) return submenu;
    }

    return MenuTree.find(m => { return m.id === "HOME" });
};