import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import  { GetQnAList } from '../Util/Data';

import IconPlus from '../Svg/IconPlus';
import IconMinus from '../Svg/IconMinus';
import "../Css/Qna.css";

const Qna = (props) => {
    const navigate = useNavigate();
    const [stateQna, setStateQna] = useState("QNA-1");
    const [stateQnAList, setStateQnAList] = useState([]);

    useEffect(() => {
        setStateQnAList(GetQnAList());
    }, []);




    return (
            <div className="box-col50 box-qna">
                <div className="box-qna-overlay"></div>
                <div className="box-padding">
                    <h2>שאלות נפוצות</h2>
                    <div className="divider-line"></div>
                    <div className="divider-space h2"></div>

                    {
                        stateQnAList.map(li => {
                            return (
                                <div className="q">
                                    <input type="radio" name="qna" id={ li.id } onChange={()=>{ setStateQna(li.id) }} checked={stateQna===li.id} />
                                    <h3>
                                        <IconPlus /><IconMinus />
                                        <label htmlFor={ li.id }>{ li.title }</label>
                                    </h3>
                                    <p className="a">
                                        { li.subtitle }<br/>
                                        <b><a onClick={ () => { navigate(li.path); window.scrollTo(0,0); } }>להמשך קריאה</a></b>
                                    </p>
                                </div>
                            )
                        })
                    }                    
                </div>
            </div>
    );

};

export default Qna;