
import "../Css/OccupationsDesc.css";


const OccupationsDesc = (props) => {

    return (
        <div className="box box-occupations">
            <div className="spacer"></div>

            <h1 style={{marginLeft: 'auto', marginRight: 'auto'}}>תחומי העיסוק שלנו</h1>
            <div style={{marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#C2873F', width: '140px', height: '2px', marginBottom: '22px'}}></div>
            
            {/* <p>
                משרדנו עוסק בתחומי הקניין הרוחני על ענפיו השונים,
                כגון: סימני מסחר, זכויות יוצרים, מדגמים, עוולות מסחריות,
                זכויות קניין רוחני בעבודה, ועוד. השירותים אותם מעניק
                המשרד כוללים טיפול וייצוג בענייני קניין רוחני מול
                רשות הפטנטים, סימני מסחר ומדגמים, וכן מול בתי
                המשפט ובתי הדין לעבודה.
            </p> 

            <p>
                במשרדנו מובטח לכם כי תקבלו יחס אישי וטיפול מסור בנוגע
                לענייני קניין רוחני. אנו נשמח לעמוד לרשותכם בכל
                שאלה הנוגעת לתחומים אלו.
            </p>
            */}
        </div>

    );
};

export default OccupationsDesc;