import "../Css/AboutPage.css";

const AboutPage = (props) => {

    return (
        <div className="box box-shadow box-2s box-middle">
            <div className="box-col50 box-about-page">
                <div className="box-padding">
                    <h2>אודות עו"ד אורית ליסק</h2>
                    <div className="divider-line"></div>
                    <div className="divider-space h2"></div>

                    <p>בראש המשרד עומדת עורכת הדין אורית ליסק. אורית הינה בעלת תואר ראשון במשפטים (LL.B) מהקריה האקדמית אונו (בהצטיינות), ותואר שני מחקרי (LL.M) ממרכז צבי מיתר ללימודי משפט מתקדמים באוניברסיטת תל אביב (בהצטיינות). עבודת התזה של עורכת הדין ליסק, במסגרת התואר השני, עסקה בנושא חובת תום הלב בעת הגשת בקשה לרישום סימן מסחר, וזכתה לציון 95.</p>
                    <p>את ניסיונה בתחום הקניין הרוחני צברה עורכת הדין אורית ליסק בעת התמחותה ברשות הפטנטים, סימני מסחר ומדגמים, ולאחר מכן כעורכת דין במגזר הפרטי, כעצמאית ובהמשך כשכירה במחלקת קניין רוחני באחת מפירמות עורכי הדין הגדולות והוותיקות בישראל.</p>
                    <p>במקביל לעבודתה המשפטית, עורכת הדין אורית ליסק פעילה במחוז מרכז של לשכת עורכי הדין בישראל ובעבר שימשה כסגנית יו"ר וועדת קניין רוחני במחוז וכן כסגנית יו"ר וועדת הקשר רמלה. במסגרת זו אף ארגנה עורכת הדין ליסק ערבי עיון בתחום הקניין הרוחני.</p>
                </div>
            </div>

            <div className="box-col50 box-bg-orit-portrate">&nbsp;</div>
        </div>
    );

};

export default AboutPage;