import { useNavigate } from 'react-router-dom';
import Menu from '../Components/Menu';
import MobileMenuBurger from '../Components/MobileMenuBurger';
import logo from '../img/logo.png';

import '../Css/HeaderDesktop.css';




const HeaderDesktop = (props) => {
    const navigate = useNavigate();

    return (
        <div className="header-desktop">
            <div style={{
                        display:'flex',
                        justifyContent: 'space-between',
                        padding: '0 5%'
                }}
            >
                <div className="logo">
                    <a href="javascript:" onClick={() => { navigate("/"); window.scrollTo(0,0); }}><img src={ logo } alt="לוגו - אורית ליסק משרד עוכי דין" height="61" /></a>
                </div>
        
                <Menu />
        
                <div className="call-us">
                    <div className="label">לתיאום פגישת יעוץ חייגו</div>
                    <div><a href="tel:054-4268326">054-4268326</a></div>
                </div>
        
                <MobileMenuBurger />
            </div>
        </div>
    );

};

export default HeaderDesktop;