import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetMenuItem } from '../Util/Data';

import OritHomeImg from '../img/orit-home.png';

import '../Css/Header.css';
import '../Css/HeaderHome.css';


const Header = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [stateCurrentPage, setStateCurrentPage] = useState({});

    useEffect(() => {
        setStateCurrentPage(GetMenuItem(location.pathname));
    }, [location.pathname]);


    return (
        <>
        {   (stateCurrentPage.id === "HOME")
            ?   <>
                    <div className="header-home">
                        <div className="header-home-main">
                            <div className="orit-home-pic">
                                <img src={ OritHomeImg } alt="עורכת הדין אורית ליסק" width="600" />
                            </div>
                    
                    
                            <div className="main-content">
                                <h1>
                                    <span>אורית ליסק<br/>משרד עורכי דין</span>
                                    {/* <span>- סימני מסחר, זכויות יוצרים וזכויות קניין רוחני נוספות</span> */}
                                </h1>
                                <div className="divider-space h1"></div>
                                <div className="divider-space h2"></div>
                    
                                <div className="devided-line">
                                    <p>משרדנו עוסק בתחומי הקניין הרוחני על ענפיו השונים, כגון: סימני מסחר, זכויות יוצרים, עיצובים (מדגמים), פטנטים, עוולות מסחריות, זכויות קניין רוחני בעבודה, ועוד.</p>
                                    <p>השירותים אותם מעניק המשרד כוללים טיפול וייצוג בענייני קניין רוחני מול רשות הפטנטים, סימני מסחר ועיצובים, וכן בבתי המשפט ובית הדין לעבודה.</p>
                                    <p>משרדנו פועל לרישום זכויות קניין רוחני, ובכלל זה, הגשת בקשות לרישום סימני מסחר בישראל והגשת בקשות בינלאומיות לרישום סימני מסחר באמצעות WIPO, וכן הגשת בקשות לרישום עיצובים תעשייתיים בישראל ובעולם. כמו כן, עובד המשרד בשיתוף עם משרדי עורכי פטנטים מובילים לצורך הגשת בקשות לרישום פטנטים בישראל ובעולם.</p>
                                    <p>במשרדנו מובטח לכם כי תקבלו יחס אישי וטיפול מסור בכל הנוגע לענייני קניין רוחני, ואנו כמובן נשמח לעמוד לרשותכם בכל שאלה.</p>
                                </div>
                    
                                <button onClick={() => { navigate('/אודות'); window.scrollTo(0,0); }}>אודות המשרד</button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="bubble-strip"></div>
                </>

            :   <div className="header">
                    <div style={{ display: 'flex', justifyContent: 'center',  padding: '180px 0 120px 0' }}>
                        <div>
                            <h1>{ stateCurrentPage.trajectory && stateCurrentPage.trajectory[0] }</h1>
                            <div className="divider-space h1"></div>
                            <div className="divider-line"></div>
                            <div className="divider-space h2"></div>

                            <div className="trajectory">
                                <a href="javascript:" onClick={() => { navigate("/"); window.scrollTo(0,0); }}>דף הבית</a>
                                •
                                { 
                                    (stateCurrentPage.trajectory?.length > 1)
                                    ? <> <a href="javascript:" onClick={() => { navigate(`/${stateCurrentPage.trajectory[0]}`); window.scrollTo(0,0); }}>{ stateCurrentPage.trajectory[0] }</a> 
                                        • <span>{stateCurrentPage.trajectory[1]}</span>
                                    </>
                                    : <span>{stateCurrentPage.trajectory}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        }
        </>                
    );

};

export default Header;