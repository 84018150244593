import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetMenu, GetMenuItem } from '../Util/Data';

import logo from '../img/logo.png';


const HeaderMobile = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [stateMenu, setStateMenu] = useState([]);
    const [stateCurrentPage, setStateCurrentPage] = useState({});

    useEffect(() => {
        setStateMenu(GetMenu());
        setStateCurrentPage(GetMenuItem(location.pathname));
    }, [location.pathname]);


    return (
        <div className="header-mobile">
            <input type="checkbox" id="mobile-menu-trigger" className="mobile-menu-trigger" />
            <div className="mobile-menu-overly"><label htmlFor="mobile-menu-trigger"></label></div>
            <div className="mobile-menu">
                <a className="mobile-menu-logo" onClick={() => { navigate("/"); window.scrollTo(0,0); }}><img src={ logo } alt="לוגו - אורית ליסק משרד עוכי דין" /></a>

                <ul className="mobile-menu-links">

                    { stateMenu.map((menuItem) => { 
                        return (
                            <>
                                <li key={menuItem.id} className={ (stateCurrentPage.trajectory.includes(menuItem.title)) ? 'active' : ''}>
                                    <a onClick={() => { navigate(menuItem.link); window.scrollTo(0,0); }}>{menuItem.title}</a>
                                </li>
                            
                                { (menuItem.submenu && menuItem.submenu.filter(si => si.display).length > 0)
                                    ?   <li><ul key={`sub-${menuItem.id}`}>
                                            { menuItem.submenu.filter(sm => sm.display).map((subMenuItem) => { 
                                                return (
                                                    <li key={subMenuItem.id} className={ (stateCurrentPage.id === subMenuItem.id) ? 'active' : ''}>
                                                        <a onClick={() => { navigate(subMenuItem.link); window.scrollTo(0,0); }}>{subMenuItem.title}</a>
                                                    </li>
                                                )
                                            }) }
                                        </ul></li>
                                    :   <></>
                                }
                            </>
                        )
                    }) }
                </ul>
            </div>
        </div>    
    );

};

export default HeaderMobile;