import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PageTemplate from './PageTemplate';
import  { GetQnAList, GetMenuItem } from '../Util/Data';

import IconPlus from '../Svg/IconPlus';
import IconMinus from '../Svg/IconMinus';

import '../Css/QnAPage.css';



const QnA = (props) => {
    const location = useLocation();

    const [stateQnAList, setStateQnAList] = useState([]);
    const [stateQna, setStateQna] = useState(null);
    

    useEffect(() => {
        const qna = GetMenuItem(location.pathname);
        setStateQna(qna);
    }, [location.pathname]);

    useEffect(() => {
        setStateQnAList(GetQnAList());
    }, []);

    useEffect(() => {
        if (stateQna)
            window.scrollTo(0,stateQna.top);
    }, [stateQna]);


    return (
        <PageTemplate>

            <br /><br /><br />

            <div className="box box-shadow box-middle box-QnaPage">
                <div className="box-padding">

                    <h1>שאלות נפוצות</h1>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#C2873F', width: '140px', height: '2px', marginBottom: '22px'}}></div>
                    {
                        stateQnAList.map(li => {
                            return (<>
                                <span id={li.id}></span>
                                <div className="qna-item">
                                    <div className='qna-q' onClick={ () => { setStateQna(li); window.scrollTo(0,li.top) } }>
                                        { (stateQna.id !== li.id) && <IconPlus /> }
                                        { (stateQna.id === li.id) && <IconMinus /> }
                                        <h3 key={ `q${li.id}` }>{ li.title }</h3>
                                    </div>
                                    {
                                        (stateQna.id === li.id) && 
                                            <div className='qna-a' key={ `a${li.id}` }>
                                                { li.content }
                                            </div>
                                    }
                                </div>
                            </>)
                        })
                    }                    

                </div>
            </div>

        </PageTemplate>
    );

};

export default QnA;