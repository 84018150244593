import Form from './Form';
import '../Css/Meet.css';

const Meet = (props) => {

    return (
        <div className="box-col50 box-meet">
            <div className="box-meet-overlay"></div>
            <div className="box-padding">
                <h2>לתיאום פגישת ייעוץ</h2>
                <div className="divider-line"></div>
                <div className="divider-space h2"></div>

                <Form pageType="Meet" />
            </div>
        </div>
    );
};

export default Meet;