import Form from './Form';
import IconFacebook from '../Svg/IconFacebook';
import IconLinkedin from '../Svg/IconLinkedin';
import IconPhone from '../Svg/IconPhone';
import IconEmail from '../Svg/IconEmail';
import IconWhatsapp from '../Svg/IconWhatsapp';

import "../Css/ContactUs.css";


const ContactUs = (props) => {

    return (
        <div className="box box-fullwidth box-contact-us-wide" style={{marginBottom:0}}>
            <div className="box-contact-us-wide-overlay"></div>

            <div className="box-2s box-contact-us-wide-main">

                <div className="box-col50">
                    <div className="box-padding">
                        <h2>צרו איתנו קשר</h2>
                        <div className="divider-line"></div>
                        <div className="divider-space h2"></div>

                        לתיאום פגישת ייעוץ:
                        <br /><br />

                        <h3 style={{display:'inline-block', marginLeft:'30px'}}>
                            <a style={{color: 'var(--color-text)', whiteSpace: 'nowrap'}} href="tel:054-4268326"><IconPhone /> 054-4268326</a>
                        </h3>

                        <h3 style={{display:'inline-block'}}>
                            <a style={{color: 'var(--color-text)', whiteSpace:'nowrap'}} href="mailto:orit@lissak-adv.com"><IconEmail /> orit@lissak-adv.com</a>
                        </h3>

                        <span className="social">
                            <a target="_blank" href="https://facebook.com/people/%D7%90%D7%95%D7%A8%D7%99%D7%AA-%D7%9C%D7%99%D7%A1%D7%A7/100003905236513" aria-label="קישור לחשבון פייסבוק">
                                <IconFacebook style= {{width:'12px'}} />
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/in/orit-lissak-b5b13767/" aria-label="קישור לחשבון לינקדאין">
                                <IconLinkedin />
                            </a>
                            <a target="_blank" href="https://wa.me/972544268326" aria-label="קישור לוואטסאפ">
                                <IconWhatsapp  style= {{width:'24px', height:'24px', margin:'10px' }}/>
                            </a>
                        </span>

                    </div>
                </div>

                <div className="box-col50 box-contact-us">
                    <Form pageType="ContactUs" />
                </div>

            </div>
        </div>
    );
};

export default ContactUs;