// import classes from './Loading.module.css';

import PageTemplate from './PageTemplate';
import AboutPage from '../Components/AboutPage';
import QnaMeet from '../Components/QnaMeet';
import AboutOffice from '../Components/AboutOffice';


const About = (props) => {

    return (
        <PageTemplate>
            <div className="main">
                <AboutPage />
                <AboutOffice />
                <QnaMeet />
            </div>
        </PageTemplate>
    );

};

export default About;