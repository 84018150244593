import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Blank from './Pages/Blank';
import Occupations from './Pages/Occupations';
import OccupationsTopic from './Pages/OccupationsTopic';
import ContactUs from './Pages/ContactUs';
import QnA from './Pages/QnA';
import Accessibility from './Pages/Accessibility';


import './Css/List.css';
import './Css/Box.css';
import './Css/DividerLine.css';


function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route index                        element={ <Home /> } />
            
            <Route path="About/"                element={ <About /> } />
            <Route path="אודות/"                element={ <About /> } />

            <Route path="Occupations/"           element={ <Occupations /> } />
            <Route path="תחומי עיסוק/"          element={ <Occupations /> } />
            <Route path="תחומי עיסוק/*"         element={ <OccupationsTopic /> } />

            <Route path="ContactUs/"     element={ <ContactUs /> } />
            <Route path="ContactUs/:switch"     element={ <ContactUs /> } />
            <Route path="צור קשר/"              element={ <ContactUs /> } />

            <Route path="QnA/"                  element={ <QnA /> } />
            <Route path="שאלות נפוצות/"        element={ <QnA /> } />
            <Route path="שאלות נפוצות/*"       element={ <QnA /> } />

            <Route path="Accessibility/"         element={ <Accessibility /> } />
            <Route path="הצהרת נגישות/"         element={ <Accessibility /> } />

            <Route path="*"                      element={ <Home /> } />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
