import { useParams } from 'react-router-dom';

import PageTemplate from './PageTemplate';
import Form from '../Components/Form';
import FormThankYou from '../Components/FormThankYou';

import '../Css/ContactUsPage.css';


const ContactUs = (props) => {
    let params = useParams();

    return (
        <PageTemplate>

            <div className='contact-us-overlay'></div>

            <div>
                
                <iframe
                    style={{ width:'100%', height:'500px', filter:'grayscale(100%)' }} 
                    height="100%" 
                    frameBorder="0" scrolling="no" 
                    src="https://maps.google.com/maps?v=2&amp;f=q&amp;ie=UTF8&amp;t=m&amp;iwloc=&amp;q=%D7%97%D7%99%D7%99%D7%9D%20%D7%9C%D7%A0%D7%93%D7%90%D7%95%207%D7%90%20%D7%A8%D7%9E%D7%9C%D7%94%20%D7%99%D7%A9%D7%A8%D7%90%D7%9C&amp;hl=he&amp;z=16&amp;source=s_q&amp;output=embed"
                ></iframe>


                <div className="form-container-out">
                    <div className="form-container-in">

                        <h3>צור קשר</h3>
                        { (params.switch === "ThankYou")
                                ? <FormThankYou />
                                : <Form />
                        }
                        

                    </div>
                </div>


                <div className='contact-us-boxs'>
                    <div className='contact-us-box'>
                        <i className="iconsmind iconsmind-Clock"></i>
                        <h3>שעות פעילות</h3>
                        <p>
                            <nobr>ימים א' - ה'</nobr>, <nobr>בשעות 09:00 - 18:00</nobr>
                        </p>
                    </div>

                    <div className='contact-us-box'>
                        <i className="iconsmind iconsmind-Paper-Plane"></i>
                        <h3>פרטי התקשרות</h3>
                        <p>
                            טלפון: <nobr><a href="tel:0544268126">054-4268326</a></nobr><br />
                            וואטסאפ: <nobr><a href="https://wa.me/972544268326" target='_blank'>054-4268326</a></nobr><br />
                            פקס: <nobr>077-4448830</nobr><br />
                            דוא"ל: <nobr><a href="mailto:orit@lissak-adv.com">orit@lissak-adv.com</a></nobr>
                        </p>
                    </div>

                    <div className='contact-us-box'>
                        <i className="iconsmind iconsmind-Map-Marker2"></i>
                        <h3>כתובת</h3>
                        <p>רח' חיים לנדאו 7א' רמלה, 7228607</p>
                    </div>
                </div>

            </div>

        </PageTemplate>
    );

};

export default ContactUs;