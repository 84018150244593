import { useNavigate } from "react-router-dom";
import IconCheck from '../Svg/IconCheck';

import "../Css/AboutOffice.css";
import { Navigate } from 'react-router-dom';


const AboutOffice = (props) => {
    const navigate = useNavigate();

    return (
        <div className="box box-shadow box-middle box-AboutOffice">
            <div className="box-padding">

                <h1 style={{marginLeft: 'auto', marginRight: 'auto'}}>אודות המשרד</h1>
                <div style={{marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#C2873F', width: '140px', height: '2px', marginBottom: '22px'}}></div>
                

                <p>משרדנו עוסק בתחומי הקניין הרוחני על ענפיו השונים, כגון: סימני מסחר, זכויות יוצרים, עיצובים (מדגמים), פטנטים, עוולות מסחריות, זכויות קניין רוחני בעבודה, ועוד.</p>
                <p>השירותים אותם מעניק המשרד כוללים טיפול וייצוג בענייני קניין רוחני מול רשות הפטנטים, סימני מסחר ועיצובים, וכן בבתי המשפט ובית הדין לעבודה.</p>
                <p>משרדנו פועל לרישום זכויות קניין רוחני, ובכלל זה, הגשת בקשות לרישום סימני מסחר בישראל והגשת בקשות בינלאומיות לרישום סימני מסחר באמצעות WIPO, וכן הגשת בקשות לרישום עיצובים תעשייתיים בישראל ובעולם. כמו כן, עובד המשרד בשיתוף עם משרדי עורכי פטנטים מובילים לצורך הגשת בקשות לרישום פטנטים בישראל ובעולם.</p>


                <p>
                    משרדנו עוסק במגוון תחומי הקניין הרוחני, כגון:
                </p>

                <ul className="ul-check">
                    <li>
                        <span><b><a onClick={ () => { navigate('/תחומי%20עיסוק/סימני%20מסחר');  window.scrollTo(0,0); }}>סימני מסחר</a></b> - רישום והגנה על מותגים ולוגואים בישראל ובעולם</span>.
                    </li>
                    <li>
                        <span><b><a onClick={ () => { navigate('/תחומי%20עיסוק/עיצובים'); window.scrollTo(0,0);} }>עיצובים תעשייתיים</a></b> – רישום והגנה על עיצובים של מוצרים, וכן הגנה על עיצובים לא רשומים</span>.
                    </li>
                    <li>
                        <span><b>פטנטים</b> - רישום והגנה על המצאות טכנולוגיות</span>
                    </li>
                    <li>
                        <span><b><a onClick={ () => { navigate('/תחומי%20עיסוק/זכויות%20יוצרים'); window.scrollTo(0,0);} }>הגנה על זכויות יוצרים</a></b> - הגנה על יצירות ספרותיות, אמנותיות, מוזיקליות ועוד</span>.
                    </li>
                    <li>
                        <span><b><a onClick={ () => { navigate('/תחומי%20עיסוק/גניבת%20עין'); window.scrollTo(0,0);} }>עוולות מסחריות</a></b> – הגנה מפני הטעיה ושמירה על מוניטין</span>.
                    </li>
                    <li>
                        <span><b><a onClick={ () => { navigate('/תחומי%20עיסוק/גזל%20סוד%20מסחרי'); window.scrollTo(0,0);} }>סודות מסחריים</a></b> – הגנה על מידע עסקי חסוי</span>.
                    </li>
                    <li>
                        <span><b>זכויות קניין רוחני בעבודה</b>, ועוד </span>.
                    </li>
                </ul>

                
                <p>במסגרת השירותים המשפטיים אותם מעניק המשרד תוכלו לקבל:</p>

                <ul className="ul-check">
                    <li>
                        <span><b>ייעוץ</b>  לגבי אפשרויות ההגנה על היצירות, ההמצאות והמותגים שלכם</span>.
                    </li>
                    <li>
                        <span><b>סיוע באכיפת זכויות קניין רוחני</b>: טיפול בתביעות בגין הפרת סימני מסחר, זכויות יוצרים וזכויות קניין רוחני נוספות; כתיבת מכתבי ההתראה וניהול משא ומתן לצורך הגעה להסדר</span>.
                    </li>
                    <li>
                        <span><b>ניסוח הסכמים וחוזים</b>: הסכמי רישיון, הסכמי סודיות והסכמים נוספים הקשורים לקניין רוחני</span>.
                    </li>
                </ul>
                
                <p>
                    נשמח לעמוד לרשותכם בכל שאלה. 
                </p>

            </div>
        </div>

    );
};

export default AboutOffice;