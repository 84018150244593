import HeaderDesktop from "../Components/HeaderDesktop";
import HeaderMobile from "../Components/HeaderMobile";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const PageTemplate = (props) => {

    return (
        <>
            <HeaderDesktop />
            <HeaderMobile />
            <Header />
            { props.children }
            <Footer />
        </>
    );
};

export default PageTemplate;